import { ErrorMessage, Field, useField } from "formik";
import React from "react";

export const TextInput = ({
  className,
  label,
  name,
  type,
  placeholder,
  required,
  isOnlyAlphanumeric,
  disabled,
  isNric,
}) => {
  const [field, meta, helpers] = useField({ name });

  const formatValue = (input) => {
    // Remove all non-digit characters
    const digits = input.replace(/\D/g, '');

    // Format digits as XXXXXX-XX-XXXX
    const match = digits.match(/^(\d{0,6})(\d{0,2})(\d{0,4})$/);
    if (match) {
      return `${match[1]}${match[2] ? '-' + match[2] : ''}${match[3] ? '-' + match[3] : ''}`;
    }
    return '';
  };

  return (
    <div className={`${className} flex flex-col gap-2`}>
      {label ? (
        <label htmlFor={name} className="text-gray-900 text-sm font-medium">
          {label}
          {required && <span className="text-red-500">*</span>}
        </label>
      ) : null}
      <Field
        id={name}
        name={name}
        type={type}
        onChange={(e) => {
          let value = e.target.value;

          const alphanumericRegex = /^[a-zA-Z0-9]*$/;
          if (isOnlyAlphanumeric && !alphanumericRegex.test(value)) return;

          const nricRegex = /^-?\d*$/;
          if (isNric) {
            // value = value.replace(/\s+/g, "");
            // if (!nricRegex.test(value)) return;

            // Limit the length to 12 digits (6-2-4)
            if (value.replace(/\D/g, '').length > 12) return;
            value = formatValue(value);
          }
          helpers.setValue(value);
        }}
        placeholder={placeholder}
        className={`px-3 py-2 rounded-lg border text-gray-800 focus:outline-none focus:border-blue-500 ${
          meta.touched && meta.error
            ? "border-red-600 bg-red-50"
            : "border-gray-300 bg-gray-50"
        }`}
      />
      <ErrorMessage
        name={name}
        component="div"
        className="text-red-500 text-sm mt-1"
      />
    </div>
  );
};
